// JQuery => Inputmask
import Inputmask from "inputmask";
window.Inputmask = Inputmask;

// JQuery UI
import "script-loader!jquery-ui/dist/jquery-ui.min.js";
import "jquery-ui/dist/themes/base/jquery-ui.min.css";

// JQuery - Datepicker
import "script-loader!jquery-ui/ui/widgets/datepicker";

// JQuery - Timepicker
import "script-loader!jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.min.js";
import "jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.min.css";